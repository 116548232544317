import {
  ButtonV2,
  ButtonV2Props,
} from 'libs/growth-platform-brand-system-v2/src/components/ButtonV2';
import { ButtonTracking } from 'libs/growth-platform-brand-system-v2/src/utils/tracking/ButtonTracking';
import { morphism } from 'morphism';
import React, { ReactElement } from 'react';
import { schemaDatoButton } from '../../morphismSchema/schemaDatoButton';
import { TitleWithArrow } from 'libs/growth-platform-brand-system-v2/src/components/ButtonV2/TitleWithArrow';

const accessToCategory = {
  fr: 'Accéder à la catégorie',
  en: 'Access to category',
  es: 'Accede a la categoría',
};

export const getBlogListCtaButtons = ({
  category,
  locale,
  index: key,
  section,
}: {
  category: any;
  locale: keyof typeof accessToCategory;
  index?: number;
  section?: string;
}): ReactElement<ButtonV2Props>[] => {
  const subCategoryButton = (
    <ButtonTracking
      component={ButtonV2}
      labelTracking={accessToCategory[locale]}
      {...morphism(schemaDatoButton, {
        internalUrl: {
          slug: category.slug,
        },
        locale,
        variant: 'primary',
        className: 'mt-24',
        title: accessToCategory[locale],
        isBlogArticle: true,
      })}
      section={section}
      index={key}
      key={`subCategoryButton-${key}`}
    />
  );
  const highlightsButtons =
    category?.highlights?.length > 0
      ? category.highlights.map((subcategory: any, i: number) => {
          const cta = morphism(schemaDatoButton, {
            internalUrl: {
              slug: subcategory.slug,
            },
            locale: locale,
            variant: 'minimal',
            className: 'mt-8 text-left justify-start',
            title: (
              <span>
                <TitleWithArrow
                  title={
                    subcategory.hero?.[0].title ||
                    subcategory?.metaFields?.title
                  }
                />
              </span>
            ),
            isBlogArticle: true,
          });
          return (
            <div className="w-full" key={`highlightsButtons-${key}-${i}`}>
              <ButtonTracking
                component={ButtonV2}
                {...cta}
                labelTracking={cta.title}
                section={section}
                index={i}
              />
            </div>
          );
        })
      : [];
  return [...highlightsButtons, subCategoryButton];
};
